import { createRouter, createWebHistory } from 'vue-router'
import getUser from "@/composables/getUser";
import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'
import Dashboard from '@/views/Dashboard.vue'
import Businesses from '@/views/Businesses.vue'
import Business from '@/views/Business.vue'
import Settings from '@/views/Settings.vue'
import Search from '@/views/Search.vue'
import Customers from '@/views/Customers.vue'
import Locations from '@/views/Locations.vue'
import Quotes from '@/views/Quotes.vue'
import Jobs from '@/views/Jobs.vue'

const { user } = getUser();

const authUser = (to, from, next) => {
  if (!user.value) {
    next({ name: 'Login' })
  } else {
    // console.log('verified auth user')
    next()
  }
}
const authEstimator = (to, from, next) => {
  if (!user.value || !user.value.claims.business_id) {
    console.error('Not authorized', user?.value?.id, user?.value?.claims?.business_id, user?.value?.claims?.user_role)
    next({ name: 'Login' })
  } else {
    // console.log('verified business user')
    next()
  }
}

const authBusiness = (to, from, next) => {
  if (!user.value || !user.value.claims.business_id || user.value.claims.user_role == 'estimator') {
    console.error('Not authorized', user?.value?.id, user?.value?.claims?.business_id, user?.value?.claims?.user_role)
    next({ name: 'Login' })
  } else {
    // console.log('verified business user')
    next()
  }
}

const authSuperadmin = (to, from, next) => {
  if (!user.value || (user.value.claims.user_role !== 'superadmin' && user.value.claims.user_role !== 'masquerade')) {
    console.error('Not authorized', user?.value?.id, user?.value?.claims?.business_id, user?.value?.claims?.user_role)
    next({ name: 'Login' })
  } else {
    // console.log('verified superadmin')
    next()
  }
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    beforeEnter: authUser,
  },
  {
    path: '/businesses',
    name: 'Businesses',
    component: Businesses,
    children: [
      {
        path: '',
        component: () => import('@/components/BusinessesList.vue'),
        beforeEnter: authSuperadmin,
      },
      {
        path: 'add',
        component: () => import('@/components/BusinessesAdd.vue'),
        beforeEnter: authSuperadmin,
      },
    ]
  },
  {
    path: '/business',
    name: 'Settings',
    component: Settings,
    children: [
      {
        path: 'settings',
        props: { tab: 'settings', },
        component: () => import('@/components/business/BusinessView.vue'),
        beforeEnter: authBusiness,
      },
      {
        path: 'settings/edit',
        component: () => import('@/components/business/BusinessEdit.vue'),
        beforeEnter: authBusiness,
      },
      {
        path: 'services',
        props: { tab: 'services', },
        component: () => import('@/components/business/BusinessView.vue'),
        beforeEnter: authBusiness,
      },
      {
        path: 'services/add',
        component: () => import('@/components/business/ServiceAdd.vue'),
        beforeEnter: authBusiness,
      },
      {
        path: 'services/:service_id',
        props: true,
        component: () => import('@/components/business/ServiceEdit.vue'),
        beforeEnter: authBusiness,
      },
      {
        path: 'users',
        props: { tab: 'users', },
        component: () => import('@/components/business/BusinessView.vue'),
        beforeEnter: authBusiness,
      },
      {
        path: 'users/add',
        component: () => import('@/components/business/UserAdd.vue'),
        beforeEnter: authBusiness,
      },
      {
        path: 'users/:user_id',
        props: true,
        component: () => import('@/components/business/UserEdit.vue'),
        beforeEnter: authBusiness,
      },
      {
        path: 'crews',
        props: { tab: 'crews', },
        component: () => import('@/components/business/BusinessView.vue'),
        beforeEnter: authBusiness,
      },
      {
        path: 'crews/add',
        component: () => import('@/components/business/CrewAdd.vue'),
        beforeEnter: authBusiness,
      },
      {
        path: 'crews/:crew_id',
        props: true,
        component: () => import('@/components/business/CrewEdit.vue'),
        beforeEnter: authBusiness,
      },
      {
        path: 'customertypes',
        props: { tab: 'customertypes', },
        component: () => import('@/components/business/BusinessView.vue'),
        beforeEnter: authBusiness,
      },
      {
        path: 'customertypes/add',
        props: true,
        component: () => import('@/components/business/CustomerTypeAdd.vue'),
        beforeEnter: authBusiness,
      },
      {
        path: 'customertypes/:customertype_id',
        props: true,
        component: () => import('@/components/business/CustomerTypeEdit.vue'),
        beforeEnter: authBusiness,
      },
    ]
  },
  {
    path: '/business/:id',
    name: 'Business',
    component: Business,
    children: [
      {
        path: 'settings',
        props: route => ({
          id: route.params.id,
          tab: 'settings',
        }),
        component: () => import('@/components/business/BusinessView.vue'),
        beforeEnter: authSuperadmin,
      },
      {
        path: 'settings/edit',
        props: true,
        component: () => import('@/components/business/BusinessEdit.vue'),
        beforeEnter: authSuperadmin,
      },
      {
        path: 'services',
        props: route => ({
          id: route.params.id,
          tab: 'services',
        }),
        component: () => import('@/components/business/BusinessView.vue'),
        beforeEnter: authSuperadmin,
      },
      {
        path: 'services/add',
        props: true,
        component: () => import('@/components/business/ServiceAdd.vue'),
        beforeEnter: authSuperadmin,
      },
      {
        path: 'services/:service_id',
        props: true,
        component: () => import('@/components/business/ServiceEdit.vue'),
        beforeEnter: authSuperadmin,
      },
      {
        path: 'users',
        props: route => ({
          id: route.params.id,
          tab: 'users',
        }),
        component: () => import('@/components/business/BusinessView.vue'),
        beforeEnter: authSuperadmin,
      },
      {
        path: 'users/add',
        props: true,
        component: () => import('@/components/business/UserAdd.vue'),
        beforeEnter: authSuperadmin,
      },
      {
        path: 'users/:user_id',
        props: true,
        component: () => import('@/components/business/UserEdit.vue'),
        beforeEnter: authSuperadmin,
      },
      {
        path: 'crews',
        props: route => ({
          id: route.params.id,
          tab: 'crews',
        }),
        component: () => import('@/components/business/BusinessView.vue'),
        beforeEnter: authSuperadmin,
      },
      {
        path: 'crews/add',
        component: () => import('@/components/business/CrewAdd.vue'),
        beforeEnter: authSuperadmin,
      },
      {
        path: 'crews/:crew_id',
        props: true,
        component: () => import('@/components/business/CrewEdit.vue'),
        beforeEnter: authSuperadmin,
      },
      {
        path: 'customertypes',
        props: route => ({
          id: route.params.id,
          tab: 'customertypes',
        }),
        component: () => import('@/components/business/BusinessView.vue'),
        beforeEnter: authSuperadmin,
      },
      {
        path: 'customertypes/add',
        props: true,
        component: () => import('@/components/business/CustomerTypeAdd.vue'),
        beforeEnter: authSuperadmin,
      },
      {
        path: 'customertypes/:customertype_id',
        props: true,
        component: () => import('@/components/business/CustomerTypeEdit.vue'),
        beforeEnter: authSuperadmin,
      },
    ]
  },
  {
    path: '/search',
    name: 'Search',
    component: Search,
    beforeEnter: authBusiness,
  },
  {
    path: '/customers',
    name: 'Customers',
    component: Customers,
    children: [
      {
        path: '',
        component: () => import('@/components/CustomersList.vue'),
        beforeEnter: authBusiness,
      },
      {
        path: 'add',
        name: 'CustomersAdd',
        component: () => import('@/components/CustomersAdd.vue'),
        beforeEnter: authBusiness,
      },
      {
        path: ':customer_id',
        props: true,
        component: () => import('@/components/CustomersView.vue'),
        beforeEnter: authBusiness,
      },
      {
        path: ':customer_id/edit',
        props: true,
        component: () => import('@/components/CustomersEdit.vue'),
        beforeEnter: authBusiness,
      },
    ],
  },
  {
    // auth is true and user role is other than superadmin
    path: '/locations',
    name: 'Locations',
    component: Locations,
    children: [
      {
        path: '',
        component: () => import('@/components/LocationsList.vue'),
        beforeEnter: authBusiness,
      },
      {
        path: 'add',
        name: 'LocationsAdd',
        component: () => import('@/components/LocationsAdd.vue'),
        beforeEnter: authBusiness,
      },
      {
        path: ':location_id',
        props: true,
        component: () => import('@/components/LocationsView.vue'),
        beforeEnter: authBusiness,
      },
      {
        path: ':location_id/edit',
        props: true,
        component: () => import('@/components/LocationsEdit.vue'),
        beforeEnter: authBusiness,
      },
    ],
  },
  {
    path: '/quotes',
    name: 'Quotes',
    component: Quotes,
    children: [
      {
        path: '',
        name: 'QuotesList',
        component: () => import('@/components/QuotesList.vue'),
        beforeEnter: authEstimator,
      },
      {
        path: 'add',
        redirect: '/quotes',
      },
      {
        path: 'mapview',
        component: () => import('@/components/QuotesMap.vue'),
        beforeEnter: authEstimator,
      },
      {
        path: 'add/:customer_id/:location_id',
        props: true,
        component: () => import('@/components/QuotesAdd.vue'),
        beforeEnter: authBusiness,
      },
      {
        path: ':quote_id/email',
        props: true,
        component: () => import('@/components/QuotesEmail.vue'),
        beforeEnter: authEstimator,
      },
      {
        path: ':quote_id',
        name: 'QuotesView',
        props: true,
        component: () => import('@/components/QuotesView.vue'),
        beforeEnter: authEstimator,
      },
      {
        path: ':quote_id/edit',
        props: true,
        component: () => import('@/components/QuotesEdit.vue'),
        beforeEnter: authEstimator,
      },
    ],
  },
  {
    path: '/jobs',
    name: 'Jobs',
    component: Jobs,
    children: [
      {
        path: '',
        name: 'JobsList',
        component: () => import('@/components/JobsList.vue'),
        beforeEnter: authBusiness,
      },
      {
        path: 'mapview',
        component: () => import('@/components/JobsMap.vue'),
        beforeEnter: authBusiness,
      },
      {
        path: ':job_id',
        name: 'JobsView',
        props: true,
        component: () => import('@/components/JobsView.vue'),
        beforeEnter: authBusiness,
      },
      {
        path: ':job_id/edit',
        props: true,
        component: () => import('@/components/JobsEdit.vue'),
        beforeEnter: authBusiness,
      },
      {
        path: ':job_id/email',
        props: true,
        component: () => import('@/components/JobsEmail.vue'),
        beforeEnter: authBusiness,
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
