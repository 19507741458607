<template>
  <div class="py-20 bg-orange-600 text-white">
    <div class="text-center font-semibold text-2xl m-auto laptop:w-full">
      Our number 1 goal is to make <br class="block tablet:hidden" />software
      that's <br class="hidden tablet:block laptop:hidden" />simple and gets the
      <br class="block tablet:hidden laptop:block" />
      intended task completed <br class="hidden tablet:block laptop:hidden" />in
      the <br class="block tablet:hidden" />most professional way possible
    </div>
    <div
      class="pt-10 flex flex-col items-center gap-10 laptop:pt-12 laptop:flex-row laptop:justify-center laptop:gap-20"
    >
      <div
        class="-ml-10 flex flex-row gap-4 items-start laptop:ml-0 laptop:flex-col laptop:items-center"
      >
        <img class="mt-0.5" :src="require('@/assets/home/icon_android.png')" />
        <div class="flex flex-col items-center">
          <div class="font-bold text-5xl laptop:text-4xl">12,345</div>
          <div class="font-semibold text-base">Android Users</div>
        </div>
      </div>
      <div
        class="-ml-10 flex flex-row gap-4 items-start laptop:ml-0 laptop:flex-col laptop:items-center"
      >
        <img class="mt-0.5" :src="require('@/assets/home/icon_iphone.png')" />
        <div class="flex flex-col items-center">
          <div class="font-bold text-5xl laptop:text-4xl">12,345</div>
          <div class="font-semibold text-base">iPhone Users</div>
        </div>
      </div>
      <div
        class="-ml-10 flex flex-row gap-4 items-start laptop:ml-0 laptop:flex-col laptop:items-center"
      >
        <img
          class="mt-0.5"
          :src="require('@/assets/home/icon_downloads.png')"
        />
        <div class="flex flex-col items-center">
          <div class="font-bold text-5xl laptop:text-4xl">123,456</div>
          <div class="font-semibold text-base">Daily Downloads</div>
        </div>
      </div>
      <div
        class="-ml-10 flex flex-row gap-4 items-start laptop:ml-0 laptop:flex-col laptop:items-center"
      >
        <img
          class="mt-0.5"
          :src="require('@/assets/home/icon_subscriptions.png')"
        />
        <div class="flex flex-col items-center">
          <div class="font-bold text-5xl laptop:text-4xl">123,456</div>
          <div class="font-semibold text-base">Subscriptions</div>
        </div>
      </div>
    </div>
  </div>
</template>
