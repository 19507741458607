<template>
  <div
    class="pt-20 pb-60 bg-contact bg-bottom bg-no-repeat bg-cover laptop:pt-0"
  >
    <div
      class="text-xl font-semibold w-96 m-auto text-center laptop:text-2xl laptop:w-full"
    >
      We are currently looking for businesses to help us Beta test the software.
    </div>
    <div
      class="text-md w-96 m-auto text-center py-4 laptop:text-xl laptop:w-full"
    >
      <div>
        Being a Beta Tester will come <br class="laptop:hidden" />
        with many benefits down the road.<br />
        If your interested, please
      </div>
    </div>
    <div>
      <div class="text-2xl font-semibold text-center laptop:text-4xl mb-2">
        Fill Out The Form Below
      </div>
      <div class="text-md text-center font-semibold pb-10 laptop:text-2xl">
        And We Will Contact You ASAP!
      </div>
      <div class="mx-auto mb-5 px-10 max-w-4xl text-lg">
        <input
          class="w-full border rounded mb-5 px-4 py-2"
          v-model="contact.name"
          placeholder="Name:"
        />
        <input
          class="w-full border rounded mb-5 px-4 py-2"
          v-model="contact.email"
          placeholder="Email:"
        />
        <input
          class="w-full border rounded mb-5 px-4 py-2"
          v-model="contact.phone"
          placeholder="Phone:"
        />
        <input
          class="w-full border rounded mb-5 px-4 py-2"
          v-model="contact.website"
          placeholder="Website:"
        />
      </div>
      <div class="text-center pt-2">
        <button
          @click.prevent="submitContact"
          class="bg-orange-600 submit-btn text-white text-2xl font-bold w-60 rounded py-2"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  setup() {
    const contact = ref({});

    const submitContact = async () => {
      // console.log("contact form submitted", contact.value);
    };

    return { contact, submitContact };
  },
};
</script>

<style scoped>
.bg-contact {
  background-image: url(../../assets/home/contact_bg_mobile.png);
}
@media (min-width: 1040px) {
  .bg-contact {
    background-image: url(../../assets/home/contact_bg_desktop.png);
  }
}
</style>
