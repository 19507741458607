<template>
  <div class="search flex h-screen overflow-y-scroll">
    <Sidebar :user="user" />
    <div class="flex-grow">
      <div class="bg-blue-500 flex py-4 px-10 h-10"></div>

      <div class="search-form p-8">
        <div class="mx-auto max-w-5xl space-y-6">
          <h1 class="text-3xl font-semibold">Search</h1>
          <div class="max-w-3xl text-lg space-y-6">
            <div class="w-full flex gap-2">
              <input
                v-model="search"
                name="search"
                class="w-full border rounded px-4 py-2"
              />
              <button
                @click="search = null"
                class="px-4 py-1 bg-blue-500 rounded text-white font-semibold cursor-pointer"
              >
                Clear
              </button>
            </div>

            <div v-if="filteredCustomers?.length > 0">
              <label class="block text-sm font-semibold text-gray-600">
                Customer
              </label>
              <div class="mt-1 border rounded border-gray-200 border-b-0">
                <table class="w-full">
                  <tr
                    v-for="customer in filteredCustomers"
                    :key="customer.id"
                    @click="goTo(`/customers/${customer.id}`)"
                    class="hover:bg-gray-100 border-b border-gray-200"
                  >
                    <td class="px-4 py-1 w-1/3">
                      {{ customer.customer_name }}
                    </td>
                    <td class="px-4 py-1 w-1/3">
                      {{ customer.customer_phone }}
                    </td>
                    <td class="px-4 py-1 w-1/3">
                      {{ customer.billing_business ?? "---" }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>

            <div v-if="search?.length > 0" class="mt-2 flex justify-center">
              <router-link
                to="/customers/add"
                class="px-4 py-1 bg-blue-500 rounded text-white font-semibold cursor-pointer"
              >
                Add New Customer
              </router-link>
            </div>

            <div v-if="filteredLocations?.length > 0">
              <label class="block text-sm font-semibold text-gray-600">
                Location
              </label>
              <div class="mt-1 border rounded border-gray-200 border-b-0">
                <table class="w-full">
                  <tr
                    v-for="location in filteredLocations"
                    :key="location.id"
                    @click="goTo(`/locations/${location.id}`)"
                    class="hover:bg-gray-100 border-b border-gray-200"
                  >
                    <td class="px-4 py-1 w-1/3">
                      {{ location.location_address1 }}
                    </td>
                    <td class="px-4 py-1 w-1/3">
                      {{ location.location_address2 }}
                    </td>
                    <td class="px-4 py-1 w-1/3">
                      {{ location.location_city }}
                      {{ location.location_state }}
                      {{ location.location_zip }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>

            <div v-if="search?.length > 0" class="mt-2 flex justify-center">
              <router-link
                to="/locations/add"
                class="px-4 py-1 bg-blue-500 rounded text-white font-semibold cursor-pointer"
              >
                Add New Location
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import getCollection from "@/composables/getCollection";
import Sidebar from "@/components/Sidebar.vue";

export default {
  components: {
    Sidebar,
  },
  props: ["user"],
  setup(props) {
    const router = useRouter();
    const search = ref(null);

    const business_id = props.user.claims.business_id ?? props.id;

    const goTo = (url) => {
      router.push(url);
    };

    const { error: customer_err, documents: customers } = getCollection(
      `businesses/${business_id}/customers`
    );

    const { error: location_err, documents: locations } = getCollection(
      `businesses/${business_id}/locations`
    );

    const filteredCustomers = computed(() => {
      if (search.value) {
        return customers.value.filter(
          (c) =>
            c.customer_phone
              .replace(/[^0-9]/g, "")
              .replace(/^1+/g, "")
              .includes(search.value) ||
            c.customer_name
              .toLowerCase()
              .includes(search.value.toLowerCase()) ||
            c.billing_business
              ?.toLowerCase()
              .includes(search.value.toLowerCase())
        );
      }
      return null;
    });

    const filteredLocations = computed(() => {
      if (search.value) {
        return locations.value.filter(
          (l) =>
            l.location_address1
              .toLowerCase()
              .includes(search.value.toLowerCase()) ||
            l.location_address2
              ?.toLowerCase()
              .includes(search.value.toLowerCase()) ||
            l.location_city
              .toLowerCase()
              .includes(search.value.toLowerCase()) ||
            l.location_state
              .toLowerCase()
              .includes(search.value.toLowerCase()) ||
            l.location_zip.replace(/[^0-9]/g, "").includes(search.value)
        );
      }
      return null;
    });

    return {
      search,
      goTo,
      customer_err,
      customers,
      location_err,
      locations,
      filteredCustomers,
      filteredLocations,
    };
  },
};
</script>
